import Collapse from "@mui/material/Collapse";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

const ProductCollapse = ({title = '', isOpen = false, children}) => {
    const [open, setOpen] = React.useState(isOpen);

    const handleClick = () => {
        setOpen(!open);
    };

    return(
        <>
            <div className={'w-full flex flex-row justify-between items-center py-3 bg-[#f8f8f8] shadow-md px-2 mb-3 rounded-xl'} onClick={handleClick}>

                <div className={'text-[#555] font-bold'}> { title } </div>
                <div>
                    {
                        open ?
                            <FontAwesomeIcon icon={faChevronUp} color={'#92a09f'} />
                            :
                            <FontAwesomeIcon icon={faChevronDown} color={'#92a09f'} />
                    }
                </div>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={'w-[95%] mx-auto'}>
                    { children }
                </div>
            </Collapse>
        </>
    )
}

export default ProductCollapse;