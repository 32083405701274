import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faHouse} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const HomeButton = () => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate('/home')} className={'flex flex-row shadow-md items-center justify-center border border-[#ECDFCD] h-[45px] w-[45px] rounded-xl'}>
            <FontAwesomeIcon icon={faArrowLeft} color={'#ecdfcd'} className={'font-bold m-1 w-[20px] h-[20px]'} />
            {/*<FontAwesomeIcon icon={faHouse} color={'#fff'} className={'font-bold m-1 w-[25px] h-[25px]'} />*/}
        </div>
    )
}

export default HomeButton;