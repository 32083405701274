import {useGetAllProductsQuery} from "../../../../storage/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Category from "../../components/Category";
import Loader from "../../components/Loader";
import * as React from "react";
import BasketButton from "../../components/BasketButton";
import Header from "../../components/Header";

const Menu = () => {

    const [items, setItems] = useState([]);
    const {isOrderAvailable, menuId} = useSelector(state => state.app)
    const {loading} = useSelector(state => state.loader)
    const { data, isLoading } = useGetAllProductsQuery(menuId);

    useEffect(() => {
        if(Boolean(data)){
            setItems(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
        <div className={'no-scrollbar pb-[75px]'}>
            <Header isShowHomeButton={true} />

            {
                data && data.length > 0 ? (
                    <div>
                        <Category items={items} />

                        {
                            isOrderAvailable === true ? <BasketButton /> : <></>
                        }
                        {
                            loading ? <Loader/> : null
                        }
                    </div>
                ):(
                    <Loader />
                )
            }
        </div>
    )
}

export default Menu;