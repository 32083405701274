import Header from "../../components/Header";
import DefaultImg from "../../../../assets/img/default-img.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faReadme,
} from "@fortawesome/free-brands-svg-icons";
import {createModal} from "../../utils/modal";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Home = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {isShowFeedbackForm, menuInfo, isGoogleComment, googleCommentUrl} = useSelector(state => state.app)

    return (
        <div className={'h-full flex flex-col justify-center items-center pb-[220px]'}>
            <Header />

            <div className={'flex flex-col justify-between items-center'}>
                <div className={'mb-5'}>
                    <img className={'rounded-xl'} src={menuInfo?.logo || DefaultImg} alt="Kardo QR Menü" width={125} height={125}/>
                </div>

                <div className={'text-center'}>
                    {
                        menuInfo.title ? <h1 className={'text-[#555555] text-[30px] font-bold text-center my-3'}>{ menuInfo?.title }</h1> : <></>
                    }
                    <h3 className={'text-[#555555] text-[15px] font-semibold text-center'}>{ menuInfo?.sub_title || '' }</h3>
                </div>

                {
                    isGoogleComment === true ?
                        (
                            <a href={googleCommentUrl}  rel={"noreferrer"} target={'_blank'} className={'mt-10 image-bg-thirdy text-[#555] py-1 px-2 rounded-xl text-[14px]'}>
                                Bize Google'da Yorum Yapın
                            </a>
                        ) : null
                }

                {
                    isShowFeedbackForm === true ?
                        (
                            <div className={'mt-10 image-bg-thirdy text-[#555] py-1 px-2 rounded-xl text-[14px]'} onClick={() => navigate('/feedback')}>
                                Geri Bildirim Formu
                            </div>
                        ) : null
                }

                {
                    menuInfo.table && (
                        <div className={'text-center'}>
                            <h3 className={'text-[#555555] text-[15px] font-semibold text-center'}>{menuInfo.table}</h3>
                        </div>
                    )
                }
            </div>

            <div className={'w-full h-[280px] rounded-t-[30px] flex flex-col justify-between fixed bottom-8'} style={{ }}>
                <div onClick={() => navigate('/menu')} className={'flex flex-col justify-center items-center cursor-pointer mt-7'}>
                    <FontAwesomeIcon icon={faReadme} color={'#737373'} size={'3x'} />
                    <span className={'text-[#737373]'}>{t('menu.go_menu')}</span>
                </div>

                <div onClick={() => createModal('home_info')} className={'bg-[#fff] w-full h-[55px] rounded-t-[30px] text-center pb-4'}>
                    <FontAwesomeIcon icon={faChevronUp} color={'#737373'} />

                    <div className={'flex justify-center'}>
                        <img src={require('../../assets/img/bottom-pattern.png')} alt="" width={180}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;