import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faCircleMinus, faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import DefaultImg from "../../../../assets/img/default-img.png";
import {useSelector} from "react-redux";
import BasketButton from "../../components/BasketButton";
import React, {useEffect, useState} from "react";
import {useGetProductQuery} from "../../../../storage/api";
import ProductCollapse from "../../../ImageDefault/components/Product/ProductCollapse";
import List from "@mui/material/List";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {addToCart, TranslatedAttribute} from "../../../../utils";
import {destroyModal} from "../../utils/modal";
import Loader from "../../components/Loader";

const ProductDetail = () => {
    const {isOrderAvailable} = useSelector(state => state.app)
    const {currencySymbol, basket, locale} = useSelector(state => state.app);
    const navigate = useNavigate();
    const {state} = useLocation();
    const { item } = state;
    const [quantity, setQuantity] = useState(0);
    const [variants, setVariants] = useState([]);
    const [options, setOptions] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const { data, isLoading } = useGetProductQuery({id: item.id});
    let [variantArray, setVariantArray] = useState({});
    let [optionArray, setOptionArray] = useState([]);
    const {t} = useTranslation();
    const {loading} = useSelector(state => state.loader)

    useEffect(() => {
        if(typeof data?.variants !== "undefined"){
            setVariants(data.variants);
        }
        if(typeof data?.options !== "undefined"){
            setOptions(data.options);
        }
        console.log(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        const variantArr = Object.assign({}, variantArray)
        variants.forEach(item => {
            let id = item.id;
            variantArr[id] = [];
        })
        setVariantArray(variantArr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variants])

    useEffect(() => {

        let totalPriceOption = 0;
        let totalPriceVariant = 0;
        let totalPrice = 0;

        optionArray.forEach(option => {
            totalPriceOption = totalPriceOption + parseFloat(option.price);
        })

        Object.values(variantArray).forEach(variant => {
            variant.forEach(itemData => {
                totalPriceVariant = totalPriceVariant + parseFloat(itemData.price);
            })
        })

        totalPrice = ((parseFloat(totalPriceVariant) + parseFloat(totalPriceOption)) + item.price[currencySymbol]) * quantity;

        setTotalPrice(totalPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variantArray, optionArray, quantity])

    const requiredVariantCheck = () => {

        let resultArray = {
            "isEmpty": false,
            "requiredField": ""
        };

        Object.keys(variantArray).forEach(item => {
            if(variantArray[item].length === 0){
                resultArray.isEmpty = true;
                resultArray.requiredField = variants.find(element => { return (element.id === item ? element : "") })
            }
        });

        return resultArray;
    }

    const handleSelectChange = (e) => {

        const select = e.target
        let val = select.options[select.selectedIndex].dataset.variantValue;
        if(Boolean(val)){
            val = JSON.parse(val)
            let id = val.id;
            let values = val.values[0];
            const variantArr = Object.assign({}, variantArray)
            variantArr[id] = [{"name": TranslatedAttribute(locale, values, 'name'), "value": TranslatedAttribute(locale, values, 'value'), "price": values.price}]
            setVariantArray(variantArr);
        }
    }

    const handleCheckBoxChange = (e) => {
        let val = e.target.value;
        val = JSON.parse(val)
        let isVariant = (new RegExp("true")).test(e.target.dataset.variant);
        let isCheck = e.target.checked;

        if(isVariant){
            let max = val.max;
            let id = val.id;
            let values = val.values[0];

            if(isCheck){
                if((variantArray[id].length + 1) > max){
                    toast.error(t('general.maximum_number_of_selections')+ ' ' + max);
                    e.target.checked = false;

                    return false;
                }


                const variantArr = Object.assign({}, variantArray)
                variantArr[id] = [...variantArr[id], {"name": TranslatedAttribute(locale, values, 'name'), "value": TranslatedAttribute(locale, values, 'value'), "price": values.price}]
                setVariantArray(variantArr)
            }else{
                variantArray[id].forEach((element) => {
                    if(element?.value === values.value){
                        const variantArr = Object.assign({}, variantArray)
                        variantArr[id].splice(variantArr[id].indexOf(element),1);
                        setVariantArray(variantArr)
                    }
                });
            }
        }else{
            if(isCheck){
                setOptionArray([...optionArray, val])
            }else{
                optionArray.forEach(element => {
                    if(element?.value === val.value){
                        const opts = Object.assign([], optionArray)
                        opts.splice(opts.indexOf(element),1);
                        setOptionArray(opts)
                    }
                });
            }
        }
    }

    const SelectBox = ({variant}) => {
        return (
            <select onChange={handleSelectChange} defaultValue={SelectDefaultValue(variant.id || 0)}  name={variant.id} id={variant.id} className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4'}>
                <option value={0}>{t('general.select')}</option>
                {
                    variant.options.map(option => {
                        return <option value={option.id} key={option.id} data-variant-value={`{"name": "${TranslatedAttribute(locale, variant, 'name')}", "id": "${variant.id}", "values": [{ "name" : "${TranslatedAttribute(locale, option, 'name')}", "value": "${option.id}", "price": "${option.additional_price}"}] }`}>{TranslatedAttribute(locale, option, 'name')} (+ {`${option.additional_price} ${currencySymbol}`})</option>
                    })
                }
            </select>
        )
    }

    const CheckBox = ({variant = false, option}) => {
        return (
            <div className={'w-full flex flex-col justify-start items-start'}>
                <div className={'w-full rounded-lg p-2 bg-[#f8f8f8] shadow-md mb-4'}>
                    <label className={'peer-checked:bg-red-400 flex flex-row items-center'}>
                        {
                            variant ? (
                                <input defaultChecked={IsSelected(option)} onChange={handleCheckBoxChange} type="checkbox" data-variant={true} value={`{"min": "${variant.min}", "max":"${variant.max}", "name": "${variant.name}", "id": "${variant.id}", "values": [{ "name" : "${option.name}", "value": "${option.id}", "price": "${option.additional_price}"}]}`} className={'w-[15px] h-[15px] peer mx-2'} />
                            ):(
                                <input defaultChecked={IsOptionSelected(option)} onChange={handleCheckBoxChange} type="checkbox" data-variant={false} value={`{ "name" : "${option.name}", "value": "${option.id}", "price": "${option.price}"}`} className={'w-[15px] h-[15px] peer mx-2'} />
                            )
                        }
                        {/*<input onChange={handleCheckBoxChange} type="checkbox" value={JSON.stringify(option)} className={'w-[15px] h-[15px] peer mx-2'} />*/}
                        <span> {TranslatedAttribute(locale, option, 'name')} (+{`${option.additional_price || option.price || 0} ${currencySymbol}`}) </span>
                    </label>
                </div>
            </div>
        )
    }

    const SelectDefaultValue = (variant_id) => {
        const items = variantArray[variant_id]
        if (items.length > 0)
            return items[0].value
        return ''
    }

    const IsSelected = (opt) => {
        return Object.values(variantArray).flat().findIndex(i => i.value === opt.id) > -1
    }

    const IsOptionSelected = (opt) => {
        return optionArray.findIndex(i => i.value === opt.id) > -1
    }

    const getData = {
        'requiredVariantCheck': requiredVariantCheck,
        'variantArray': variantArray,
        'optionArray': optionArray,
        'data': data,
        'quantity': quantity,
        'modal': {data: item},
        'totalPrice': totalPrice,
        'currencySymbol': currencySymbol
    };

    return (
        <>
            <div className={'bg-[#000] py-4 px-2 text-[#fff] pb-[75px] flex justify-start items-center'}>
               <div onClick={() => navigate(-1)}>
                   <FontAwesomeIcon icon={faChevronLeft} color={'#fff'} className={'font-bold mx-3 w-[20px] h-[20px]'} />
               </div>
                <span>{ TranslatedAttribute(locale, item, 'name') }</span>
            </div>

            <div className={'w-full h-1/3 flex justify-center items-center overflow-hidden'}>
                <img src={item.full_size_url || DefaultImg } alt="" className={'w-full object-cover'}/>
            </div>

            <div className={'w-full p-2'}>

                <div className={'w-full flex flex-row items-center justify-between'}>
                    <div className={'flex flex-col'}>
                        <span className={'font-bold text-[18px]'}>{ TranslatedAttribute(locale, item, 'name') }</span>
                        <span className={'font-normal text-[12px] mb-2'}>{ TranslatedAttribute(locale, item, 'description') }</span>

                        {
                            item.resource_type === 'ProductCategory' ? (
                                <></>
                            ):(
                                <span>{ (item.price[currencySymbol].toString().includes('-') ? item.price[currencySymbol] : `${parseFloat(item.price[currencySymbol]).toFixed(2)} ${currencySymbol}`) }</span>
                            )
                        }
                    </div>
                    <div className={'flex flex-row items-center justify-between'}>
                        {
                            item.resource_type === 'ProductCategory' ? (
                                <></>
                            ):(
                                <>
                                    <button className={'m-1'} onClick={() => {
                                        if( quantity !== 0){
                                            setQuantity(quantity-1)
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={faCircleMinus} className={quantity === 0 ? 'text-[#e4e4e4]' : 'text-[#000]'} size={'2x'}/>
                                    </button>
                                    <div className={'flex justify-center items-center shadow-md text-[#555555] font-bold bg-[#f8f8f8] m-1 w-[40px] h-[40px] rounded-[30px]'}>
                                        {quantity}
                                    </div>
                                    <button className={'m-1'} onClick={() => setQuantity(quantity+1)}>
                                        <FontAwesomeIcon icon={faCirclePlus} className={'text-[#000]'} size={'2x'}/>
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>

                {
                    item?.sub_items ? (
                        <div className={'bg-[#f3f4f6] p-2 mt-3'}>
                            <p className={'bg-[#f3f4f6] text-[15px]'}>{t('general.select')}</p>
                            {
                                item.sub_items.map(subItem => {
                                    return(
                                        <div key={subItem.id} onClick={() => navigate('/product-detail', { state: { item: subItem }})} className={'bg-[#fff] flex flex-row justify-between items-center m-3'}>
                                            <div className={'ml-3'}>
                                                <p className={'font-semibold'}>{ subItem.name }</p>
                                                <p>{ (`${(subItem?.price[currencySymbol]).toFixed(2)+' '+currencySymbol}`) }</p>
                                            </div>
                                            <div className={'h-[65px] flex justify-center items-center overflow-hidden'}>
                                                <img src={subItem.image_url} alt="" className={'w-[120px] object-contain'}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ):(
                        <></>
                    )
                }

                {/*<div className={'bg-[#f3f4f6] p-2 mt-3'}>*/}
                {/*    <p className={'text-[#6b7281] text-[15px]'}>{t('general.description')}</p>*/}
                {/*    <p>{ TranslatedAttribute(locale, item, 'description') }</p>*/}
                {/*</div>*/}

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop: 2 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >

                    {
                        variants && variants.length > 0 ? (
                            variants.map(variant => {
                                return variant.selection_type === 'single' ? (
                                    <ProductCollapse key={variant.id} title={`${ TranslatedAttribute(locale, variant, 'name') }`}>
                                        <SelectBox variant={variant} />
                                    </ProductCollapse>
                                ):(
                                    <ProductCollapse key={variant.id} title={ TranslatedAttribute(locale, variant, 'name') }>
                                        {
                                            variant.options.map(option => {
                                                return (
                                                    <CheckBox key={option.id} variant={variant} option={option} />
                                                )
                                            })
                                        }

                                    </ProductCollapse>
                                )
                            })
                        ):(
                            <>
                            </>
                        )
                    }

                    {
                        Object.keys(options).map((key) => {
                            return (
                                <ProductCollapse key={key} title={key}>
                                    {
                                        options[key].map(option => {
                                            return (
                                                <CheckBox key={option.id} option={option} />
                                            )
                                        })
                                    }

                                </ProductCollapse>
                            )
                        })
                    }

                </List>

                <div className={'w-full text-right flex justify-end items-end mt-[15px]'}>
                    <div className={'text-[21px]'}>
                        <span className={'font-bold text-[#555555]'}>{t('general.total')}</span> { `${(totalPrice || 0).toFixed(2)+' '+currencySymbol}` }
                    </div>
                </div>

                {
                    isOrderAvailable && (
                        <div className={'flex justify-center'}>
                            <button onClick={() => quantity > 0 ? addToCart(getData, destroyModal) : toast.error(t('general.determine_the_product_quantity'))} className={'w-1/2 rounded-3xl text-[#fff] px-5 py-3 font-bold mt-2 mb-[75px] ' + (quantity === 0 ? 'bg-[#e4e4e4]' : 'bg-[#000]') }>
                                {t('general.add_to_cart')} ({ basket.items.length })
                            </button>
                        </div>
                    )
                }

            </div>

            {
                (isOrderAvailable === true) && (item.resource_type !== '') ? <BasketButton /> : <></>
            }
            {
                loading ? <Loader/> : null
            }
        </>
    )
}

export default ProductDetail;