import Header from "../../components/Header";
import DefaultImg from "../../../../assets/img/default-img.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faFoursquare,
    faInstagram,
    faReadme,
    faTiktok,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {createModal} from "../../utils/modal";
import {faChevronUp, faCookieBite, faWifi} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Home = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {isShowFeedbackForm, menuInfo, isGoogleComment, googleCommentUrl} = useSelector(state => state.app)

    return (
        <div className={'h-full flex flex-col justify-center items-center pb-[220px]'}>
            <Header />

            <div className={'flex flex-col justify-between items-center'}>
                <div className={'mb-5'}>
                    <img className={'rounded-xl'} src={menuInfo?.logo || DefaultImg} alt="Kardo QR Menü" width={125} height={125}/>
                </div>

                <div className={'text-center'}>
                    {
                        menuInfo.title ? <h1 className={'text-[#555555] text-[30px] font-bold text-center my-3'}>{ menuInfo?.title }</h1> : <></>
                    }
                    <h3 className={'text-[#555555] text-[15px] font-semibold text-center'}>{ menuInfo?.sub_title || '' }</h3>
                </div>

                {
                    isGoogleComment === true ?
                        (
                            <a href={googleCommentUrl} rel="noreferrer" target={'_blank'} className={'mt-10 bg-[#d0e9e7] text-[#555] py-1 px-2 rounded-xl text-[14px]'}>
                                Bize Google'da Yorum Yapın
                            </a>
                        ) : null
                }

                {
                    isShowFeedbackForm === true ?
                        (
                            <div className={'mt-10 bg-[#d0e9e7] text-[#555] py-1 px-2 rounded-xl text-[14px]'} onClick={() => navigate('/feedback')}>
                                Geri Bildirim Formu
                            </div>
                        ) : null
                }

                {
                    menuInfo.table && (
                        <div className={'text-center'}>
                            <h3 className={'text-[#555555] text-[15px] font-semibold text-center'}>{menuInfo.table}</h3>
                        </div>
                    )
                }
            </div>

            <div className={'no-image-bg-primary w-full h-[180px] rounded-t-[30px] flex flex-col justify-between fixed bottom-8'} style={{ }}>
                <div onClick={() => navigate('/menu')} className={'flex flex-col justify-center items-center cursor-pointer mt-7'}>
                    <FontAwesomeIcon icon={faReadme} color={'#fff'} size={'3x'} />
                    <span className={'text-white'}>{t('menu.go_menu')}</span>
                </div>

                <div onClick={() => createModal('no_image_home_info')} className={'bg-[#fff] w-full h-[85px] rounded-t-[30px] text-center pb-4'}>
                    <FontAwesomeIcon icon={faChevronUp} />

                    <div className={'w-full mx-auto flex flex-row items-center justify-center'}>
                        <div className={'h-[42px] w-[42px] rounded-[42px] bg-[#d0e9e7] text-[#555] flex justify-center items-center mx-2'}>
                            <FontAwesomeIcon icon={faCookieBite} />
                        </div>
                        <div className={'h-[42px] w-[42px] rounded-[42px] bg-[#d0e9e7] text-[#555] flex justify-center items-center mx-2'}>
                            <FontAwesomeIcon icon={faWifi} />
                        </div>
                        {
                            menuInfo && menuInfo?.social_media && typeof menuInfo?.social_media === 'object' ? (
                                Object.keys(menuInfo?.social_media).map((socialMedia, index) => {
                                    if(Object.keys(menuInfo?.social_media).length >= 3 && index >= 3){
                                        return (<div key={socialMedia}></div>);
                                    }
                                    return (
                                        <div key={socialMedia}>
                                            {
                                                menuInfo?.social_media[socialMedia] !== "" ?
                                                    <div className={'h-[42px] w-[42px] rounded-[42px] bg-[#d0e9e7] text-[#555] flex justify-center items-center mx-2'}>
                                                        {
                                                            socialMedia === "facebook" ?
                                                                <FontAwesomeIcon icon={faFacebook} />
                                                                : socialMedia === "instagram" ?
                                                                    <FontAwesomeIcon icon={faInstagram} />
                                                                    : socialMedia === "youtube" ?
                                                                        <FontAwesomeIcon icon={faYoutube} />
                                                                        : socialMedia === "tiktok" ?
                                                                            <FontAwesomeIcon icon={faTiktok} />
                                                                            : socialMedia === "twitter" ?
                                                                                <FontAwesomeIcon icon={faTwitter} />
                                                                                : socialMedia === "foursquare" ?
                                                                                    <FontAwesomeIcon icon={faFoursquare} />
                                                                                    : <></>
                                                        }

                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    )
                                })
                            ):(
                                <></>
                            )

                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;