import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {setLocale} from "../../../../storage/app";
import {Drawer} from "@mui/material";
import React from "react";
import {destroyAllModal} from "../../utils/modal";
import {destroyModal} from "../../utils/modal"
import {useTranslation} from "react-i18next";
import Cookies from 'universal-cookie';

const LanguageModal = () => {
    const {languages, locale} = useSelector( state => state.app);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const cookies = new Cookies(null);

    const changeLanguage = (lng) => {
        dispatch(setLocale(lng));
        cookies.set('MenuLocale', lng);
        window.location.reload();
        destroyAllModal();
    };

    return (
        <>
            {
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                            boxShadow: 'none'
                        }
                    }}
                    anchor={'bottom'}
                    open={true}
                    onClose={destroyModal}
                >

                    <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-[40vh] rounded-t-[30px] p-4 relative'}>
                        <FontAwesomeIcon icon={faCircleXmark} size={'2x'} className={'no-image-text-secondary w-[40px] h-[40px] absolute'} style={{ right: 5, top: 5 }} onClick={destroyModal}/>
                        <>
                            <div className={'text-[#555555] text-[20px] font-bold text-center'}>{t('menu.select_lang')}</div>

                            {
                                Object.keys(languages).map(lang =>
                                    <div key={lang} className={'m-2 bg-[#f8f8f8] border border-[#f8f8f8] rounded-[30px] px-2 py-3 flex justify-between items-center shadow-md'} onClick={() => changeLanguage(lang)}>
                                        <div className={'flex flex-row items-center'}>
                                            <img src={require(`../../../../assets/img/langs/${lang}.png`)} alt="" className={'w-[40px] mr-2'}/>
                                            {languages[lang]}
                                        </div>
                                        {
                                            lang === locale ?
                                                (
                                                    <FontAwesomeIcon icon={faCheck} />
                                                ):(<></>)
                                        }
                                    </div>
                                )
                            }
                        </>
                    </div>
                </Drawer>
            }
        </>
    )
}


export default LanguageModal;