import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const DomainResolver = (path) => {
    // let host = 'https://kardoapp.com'
    let host = 'http://0.0.0.0:3000'
    if (process.env.NODE_ENV === 'production') {
        host = 'https://kardoapp.com'
    }
    return `${host}/${path}`
}


const dynamicBaseQuery = async (args, api, extraOptions) => {
    const baseUrl = DomainResolver(`${(api.getState()).app.restaurantId}/qr_api/`)
    const rawBaseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const locale = getState().app.locale;
            if (locale) {
                headers.set('MenuLocale', `${locale}`)
            }
            headers.set('Content-type', 'application/json');
            return headers
        },
    });
    return rawBaseQuery(args, api, extraOptions);
};

export const api = createApi({
    reducerPath:  "api",
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        getMenuInfo: builder.query({
            query: ({menuId, tableId}) => {
                let url = 'info'
                if (menuId && tableId) {
                    url = `${url}?menu_id=${menuId}&table_id=${tableId}`
                } else if (menuId) {
                    url = `${url}?menu_id=${menuId}`
                } else if (tableId) {
                    url = `${url}?table_id=${tableId}`
                }
                console.log(menuId, tableId, url)
                return url
            }
        }),
        getAllProducts: builder.query({
            query: (menuId) => menuId ? `menu?menu_id=${menuId}` : 'menu'
        }),
        getProduct: builder.query({
            query: (data) => data?.menuId ? `urun/${data?.id}?menu_id=${data?.menuId}` : `urun/${data?.id}`
        }),
        getFeedbackForm: builder.query({
            query: (menuId) => menuId ? `geri-bildirim?menu_id=${menuId}` : 'geri-bildirim'
        }),
        addNewOrder: builder.mutation({
            query: (payload) => ({
                url: `siparis`,
                method: 'POST',
                body: payload,
            }),
        }),
        sendFeedbackForm: builder.mutation({
            query: (payload) => ({
                url: `geri-bildirim`,
                method: 'POST',
                body: payload,
            }),
        })
    })
})

export const { useGetMenuInfoQuery, useGetAllProductsQuery, useGetProductQuery, useGetFeedbackFormQuery, useAddNewOrderMutation, useSendFeedbackFormMutation } = api;


