import './App.css';
import './tailwind.css';
import Logo from "./assets/img/logo-light.png";
import ThemeRoute from "./themes/ThemeRoute";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Toaster} from "react-hot-toast";
// Modals
import NoImageDefaultModals from "./themes/NoImageDefault/components/DrawerModal";
import ImageDefaultModals from "./themes/ImageDefault/components/DrawerModal";
import Theme3Modals from "./themes/Theme3/components/DrawerModal";
import Theme4Modals from "./themes/Theme4/components/DrawerModal";
import ItalianModals from "./themes/Italian/components/DrawerModal";

function App() {
    const {locale, theme} = useSelector(state => state.app)
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(locale).then();
    }, [locale, i18n]);

    const modals = {
        "no_image_default": NoImageDefaultModals,
        "image_default": ImageDefaultModals,
        "theme_3": Theme3Modals,
        "italian": ItalianModals,
        "theme_4": Theme4Modals,
    }

      return (
          <div className={'max-w-[430px] mx-auto h-[100dvh] pb-0 overflow-y-scroll no-scrollbar relative '
          + (theme === 'theme_4' ? 'bg-[#9FA690] pb-0' : '')}>
              <ThemeRoute />
              {
                  React.createElement(modals[theme] || modals['no_image_default'])
              }
              <Toaster />

              <div className={'swal2-container w-1 h-1 hidden'}></div>

              <div className={'fixed bottom-[100px] z-[3001] rotate-90 left-[-35px] text-center flex justify-center'}>
                  <div className={'w-[80px] p-1 bg-[#021321] rounded-t-xl text-[#fff] flex justify-center items-center'}>
                      <a href="https://kardoapp.com/" target={'_blank'} rel={"noreferrer"} className={'flex justify-center items-center'}>
                          <img src={Logo} width={'50%'} alt={'Logo'}/>
                      </a>
                  </div>
              </div>
          </div>
      );
}

export default App;
