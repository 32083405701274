import {Drawer} from "@mui/material";
import {destroyModal, useModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCirclePlus, faCircleXmark, faClock} from "@fortawesome/free-solid-svg-icons";
import List from "@mui/material/List";
import ProductCollapse from "../Product/ProductCollapse";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useGetProductQuery} from "../../../../storage/api";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {addToCart, TranslatedAttribute} from "../../../../utils";
import AllergenIcon from "../../../ImageDefault/components/AllergenIcon";

const ProductDetailModal = () => {

    const modal = useModal();
    const {currencySymbol, basket, locale} = useSelector(state => state.app);
    const [quantity, setQuantity] = useState(1);
    const [variants, setVariants] = useState([]);
    const [options, setOptions] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const { data, isLoading } = useGetProductQuery({id: modal.data.id});
    const {t} = useTranslation();
    let [variantArray, setVariantArray] = useState({});
    let [optionArray, setOptionArray] = useState([]);
    const {isOrderAvailable} = useSelector(state => state.app)

    useEffect(() => {
        console.log(modal.data)
        if(typeof data?.variants !== "undefined"){
            setVariants(data.variants);
        }
        if(typeof data?.options !== "undefined"){
            setOptions(data.options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        const variantArr = Object.assign({}, variantArray)
        variants.forEach(item => {
            let id = item.id;
            variantArr[id] = [];
        })
        setVariantArray(variantArr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variants])

    useEffect(() => {

        let totalPriceOption = 0;
        let totalPriceVariant = 0;
        let totalPrice = 0;

        optionArray.forEach(option => {
            totalPriceOption = totalPriceOption + parseFloat(option.price);
        })

        Object.values(variantArray).forEach(variant => {
            variant.forEach(item => {
                totalPriceVariant = totalPriceVariant + parseFloat(item.price);
            })
        })

        totalPrice = ((parseFloat(totalPriceVariant) + parseFloat(totalPriceOption)) + modal.data.price[currencySymbol]) * quantity;

        setTotalPrice(totalPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variantArray, optionArray, quantity])


    const requiredVariantCheck = () => {

        let resultArray = {
            "isEmpty": false,
            "requiredField": ""
        };

        Object.keys(variantArray).forEach(item => {
            if(variantArray[item].length === 0){
                resultArray.isEmpty = true;
                resultArray.requiredField = variants.find(element => { return (element.id === item ? element : "") })
            }
        });

        return resultArray;
    }

    const handleSelectChange = (e) => {

        const select = e.target
        let val = select.options[select.selectedIndex].dataset.variantValue;
        if(Boolean(val)){
            val = JSON.parse(val)
            let id = val.id;
            let values = val.values[0];
            const variantArr = Object.assign({}, variantArray)
            variantArr[id] = [{"name": TranslatedAttribute(locale, values, 'name'), "value": TranslatedAttribute(locale, values, 'value'), "price": values.price}]
            setVariantArray(variantArr);
        }
    }

    const handleCheckBoxChange = (e) => {
        let val = e.target.value;
        val = JSON.parse(val)
        let isVariant = (new RegExp("true")).test(e.target.dataset.variant);
        let isCheck = e.target.checked;

        if(isVariant){
            let max = val.max;
            let id = val.id;
            let values = val.values[0];

            if(isCheck){
                if((variantArray[id].length + 1) > max){
                    toast.error(t('general.maximum_number_of_selections')+ ' ' + max);
                    e.target.checked = false;

                    return false;
                }


                const variantArr = Object.assign({}, variantArray)
                variantArr[id] = [...variantArr[id], {"name": TranslatedAttribute(locale, values, 'name'), "value": TranslatedAttribute(locale, values, 'value'), "price": values.price}]
                setVariantArray(variantArr)
            }else{
                variantArray[id].forEach((element) => {
                    if(element?.value === values.value){
                        const variantArr = Object.assign({}, variantArray)
                        variantArr[id].splice(variantArr[id].indexOf(element),1);
                        setVariantArray(variantArr)
                    }
                });
            }
        }else{
            if(isCheck){
                setOptionArray([...optionArray, val])
            }else{
                optionArray.forEach(element => {
                    if(element?.value === val.value){
                        const opts = Object.assign([], optionArray)
                        opts.splice(opts.indexOf(element),1);
                        setOptionArray(opts)
                    }
                });
            }
        }
    }

    const SelectBox = ({variant}) => {
        return (
            <select onChange={handleSelectChange} defaultValue={SelectDefaultValue(variant.id || 0)}  name={variant.id} id={variant.id} className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4'}>
                <option value={0}>{t('general.select')}</option>
                {
                    variant.options.map(option => {
                        return <option value={option.id} key={option.id} data-variant-value={`{"name": "${TranslatedAttribute(locale, variant, 'name')}", "id": "${variant.id}", "values": [{ "name" : "${TranslatedAttribute(locale, option, 'name')}", "value": "${option.id}", "price": "${option.additional_price}"}] }`}>{TranslatedAttribute(locale, option, 'name')} (+ {`${option.additional_price} ${currencySymbol}`})</option>
                    })
                }
            </select>
        )
    }

    const CheckBox = ({variant = false, option}) => {
        return (
            <div className={'w-full flex flex-col justify-start items-start'}>
                <div className={'w-full rounded-lg p-2 bg-[#f8f8f8] shadow-md mb-4'}>
                    <label className={'peer-checked:bg-red-400 flex flex-row items-center'}>
                        {
                            variant ? (
                                <input defaultChecked={IsSelected(option)} onChange={handleCheckBoxChange} type="checkbox" data-variant={true} value={`{"min": "${variant.min}", "max":"${variant.max}", "name": "${TranslatedAttribute(locale, variant, 'name')}", "id": "${variant.id}", "values": [{ "name" : "${TranslatedAttribute(locale, option, 'name')}", "value": "${option.id}", "price": "${option.additional_price}"}]}`} className={'w-[15px] h-[15px] peer mx-2'} />
                            ):(
                                <input defaultChecked={IsOptionSelected(option)} onChange={handleCheckBoxChange} type="checkbox" data-variant={false} value={`{ "name" : "${TranslatedAttribute(locale, option, 'name')}", "value": "${option.id}", "price": "${option.price}"}`} className={'w-[15px] h-[15px] peer mx-2'} />
                            )
                        }
                        {/*<input onChange={handleCheckBoxChange} type="checkbox" value={JSON.stringify(option)} className={'w-[15px] h-[15px] peer mx-2'} />*/}
                        <span> {TranslatedAttribute(locale, option, 'name')} (+{`${option.additional_price || option.price || 0} ${currencySymbol}`}) </span>
                    </label>
                </div>
            </div>
        )
    }

    const SelectDefaultValue = (variant_id) => {
        const items = variantArray[variant_id]
        if (items.length > 0)
            return items[0].value
        return ''
    }

    const IsSelected = (opt) => {
        return Object.values(variantArray).flat().findIndex(i => i.value === opt.id) > -1
    }

    const IsOptionSelected = (opt) => {
        return optionArray.findIndex(i => i.value === opt.id) > -1
    }

    const getData = {
        'requiredVariantCheck': requiredVariantCheck,
        'variantArray': variantArray,
        'optionArray': optionArray,
        'data': data,
        'quantity': quantity,
        'modal': modal,
        'totalPrice': totalPrice,
        'currencySymbol': currencySymbol
    };

    const addToCartHandle = (data) => {
        addToCart(data, destroyModal);
    }

    return(
        <>
            {
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                            boxShadow: 'none'
                        }
                    }}
                    anchor={'bottom'}
                    open={true}
                    onClose={destroyModal}
                >

                    <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-full rounded-t-[30px] p-4 relative'}>
                        <FontAwesomeIcon icon={faCircleXmark} size={'2x'} className={'no-image-text-secondary w-[40px] h-[40px] absolute'} style={{ right: 5, top: 5 }} onClick={destroyModal}/>
                        <div className={'overflow-scroll no-scrollbar max-h-[480px] flex flex-col justify-start items-center pb-6 mt-10 relative'}>

                            {
                                isOrderAvailable && (
                                    <div className={'w-full flex flex-col justify-center text-center'}>
                                        <span className={'text-[22px] font-bold text-[#555555] mb-2'}>{t('general.add_to_cart')}</span>
                                        <span className={'text-[16px] text-[#555555] mb-5'}>{t('general.product_detail_sub_title')}</span>
                                    </div>
                                )
                            }

                            <div className={'flex flex-row my-2'}>
                                {
                                    (TranslatedAttribute(locale, modal.data, 'ingredient_tags')).map((tag, idx) => {
                                        return (
                                            <AllergenIcon key={`${idx}-${tag}`} tag={tag} />
                                        )
                                    })
                                }
                            </div>

                            <div className={'w-full flex flex-row items-center justify-between'}>

                                <div className={'flex flex-col'}>
                                    <span className={'font-bold'}>{ TranslatedAttribute(locale, modal.data, 'name') }</span>
                                    <span className={'font-normal text-[12px] mb-2'}>{ TranslatedAttribute(locale, modal.data, 'description') }</span>
                                    <span>{ (`${(modal.data?.price[currencySymbol]).toFixed(2)+' '+currencySymbol}`) }</span>
                                </div>

                                <div>
                                    <div className={'w-full flex flex-row items-center justify-between'}>
                                        <div className={'flex flex-row items-center justify-between'}>
                                            <button className={'m-1'} onClick={() => {
                                                if( quantity !== 0){
                                                    setQuantity(quantity-1)
                                                }
                                            }}>
                                                <FontAwesomeIcon icon={faCircleMinus} className={quantity === 0 ? 'text-[#d3e5e2]' : 'no-image-text-primary'} size={'2x'}/>
                                            </button>
                                            <div className={'flex justify-center items-center shadow-md text-[#555555] font-bold bg-[#f8f8f8] m-1 w-[40px] h-[40px] rounded-[30px]'}>
                                                {quantity}
                                            </div>
                                            <button className={'m-1'} onClick={() => setQuantity(quantity+1)}>
                                                <FontAwesomeIcon icon={faCirclePlus} className={'no-image-text-primary'} size={'2x'}/>
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        modal.data.preparation_time ? (
                                            <div className={'text-center flex items-center justify-center bg-[#f8f8f8] rounded-xl mt-4'}>
                                                <span className={'m-2 text-sm'}>{ modal.data.preparation_time } {t('general.minute')} </span>
                                                <FontAwesomeIcon icon={faClock} className={'text-[#808080]'}/>
                                            </div>
                                        ):(
                                            <></>
                                        )
                                    }

                                </div>

                            </div>

                            {
                                modal.data.note ? (
                                        <div className={'bg-blue-100 px-4 py-1 rounded-xl text-xs mt-3'}>
                                            { TranslatedAttribute(locale, modal.data, 'note') }
                                        </div>
                                    ) :
                                    (
                                        <></>
                                    )
                            }

                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop: 2 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >

                                {
                                    variants && variants.length > 0 ? (
                                        variants.map(variant => {
                                            return variant.selection_type === 'single' ? (
                                                <ProductCollapse key={variant.id} title={`${TranslatedAttribute(locale, variant, 'name')}`}>
                                                    <SelectBox variant={variant} />
                                                </ProductCollapse>
                                            ):(
                                                <ProductCollapse key={variant.id} title={TranslatedAttribute(locale, variant, 'name')}>
                                                    {
                                                        variant.options.map(option => {
                                                            return (
                                                                <CheckBox key={option.id} variant={variant} option={option} />
                                                            )
                                                        })
                                                    }

                                                </ProductCollapse>
                                            )
                                        })
                                    ):(
                                    <>
                                    </>
                                    )
                                }

                                {
                                    Object.keys(options).map((key) => {
                                        return (
                                            <ProductCollapse key={key} title={key}>
                                                {
                                                    options[key].map(option => {
                                                        return (
                                                            <CheckBox key={option.id} option={option} />
                                                        )
                                                    })
                                                }

                                            </ProductCollapse>
                                        )
                                    })
                                }

                            </List>

                        </div>

                        <div className={'w-full flex justify-center flex-col'}>
                            <div className={'w-full text-right flex justify-end items-end mt-[15px]'}>
                                <div className={'text-[21px]'}>
                                    <span className={'font-bold text-[#555555]'}>{t('general.total')}</span> { `${totalPrice.toFixed(2)+' '+currencySymbol}` }
                                </div>
                            </div>

                            {
                                isOrderAvailable && modal.data.is_available ? (
                                    <div className={'flex justify-center'}>
                                        <button onClick={() => quantity > 0 ? addToCartHandle(getData) : toast.error(t('general.determine_the_product_quantity'))} className={'w-1/2 rounded-3xl text-[#fff] px-5 py-3 font-bold mt-2 ' + (quantity === 0 ? 'bg-[#90bcb5]' : 'no-image-bg-primary') }>
                                            {t('general.add_to_cart')} ({ basket.items.length })
                                        </button>
                                    </div>
                                ):(
                                    <></>
                                )
                            }
                        </div>
                    </div>
                </Drawer>
            }
        </>
    )
}

export default ProductDetailModal;