import {destroyAllBasket, setBasket, setBasketAfterDeleteItem} from "../storage/app";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {store} from "../storage/store";
import i18n from 'i18next';
import toast from "react-hot-toast";
import {setLoading} from "../storage/loader";

const MySwal = withReactContent(Swal)
export const deleteAllItem = () => {
    MySwal.fire({
        title: `${i18n.t('general.all_items_delete_in_basket')}`,
        showCancelButton: true,
        confirmButtonText: `${i18n.t('general.delete')}`,
        cancelButtonText: `${i18n.t('general.cancel')}`,
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch(destroyAllBasket([]));
            Swal.fire(`${i18n.t('general.successful')}`, '', 'success')
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    })
}

export const deleteItem = (identifier, basket) => {

    MySwal.fire({
        title: `${i18n.t('general.delete_the_product')}`,
        showCancelButton: true,
        confirmButtonText: `${i18n.t('general.delete')}`,
        cancelButtonText: `${i18n.t('general.cancel')}`,
    }).then((result) => {
        if (result.isConfirmed) {
            const arr = Object.assign([], basket.items);
            const filtered = arr.filter(i => i.identifier !== identifier)
            store.dispatch(setBasketAfterDeleteItem(filtered))

            Swal.fire(`${i18n.t('general.successful')}`, '', 'success')

        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    })
}

export const CalculateDistance = (lat1, lon1, lat2, lon2) => {

    return new Promise((resolve, reject) => {
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        } else {
            let radlat1 = Math.PI * lat1 / 180;
            let radlat2 = Math.PI * lat2 / 180;
            let theta = lon1 - lon2;
            let radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            resolve(dist * 1.609344 * 1000);
        }
    });
}

export const getLocation = (lat = 0, lng = 0) => {
    return new Promise((resolve, reject) => {

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                let distance = await CalculateDistance(lat, lng, position.coords.latitude, position.coords.longitude);
                resolve(distance);
            },
            (error) => {
                reject({type: 'error', title: i18n.t('general.allow_location_access'), html: i18n.t('general.you_can_order_if_you_allow_location_access'), confirm: i18n.t('general.ok')});
            }
        );
    })
}

export const setOrder = async (getData) =>{
    // getData.setLoad(true);
    store.dispatch(setLoading(true))
    if(getData.menuInfo.use_location_for_orders){
        let location = await getLocation(getData.menuInfo?.lat, getData.menuInfo?.lng);
        if(location > getData.menuInfo?.max_distance){
            // getData.setLoad(false);
            store.dispatch(setLoading(false))
            MySwal.fire({
                icon: 'error',
                title: `${i18n.t('general.order_failed')}`,
                html: `${i18n.t('general.outside_order_border')}`,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: `${i18n.t('general.ok')}`,
                allowOutsideClick: false
            }).then((result) => {
                window.location.reload();
            })
            return false;
        }
    }

    let data = {
        "qr_table_id": getData.tableId,
        "order": getData.basket.items,
        "cart_id": getData.basket.id,
        "name": getData.name,
        "note": getData.note
    }

    getData.addNewOrder(data)
        .unwrap()
        .then((response) => {
            // getData.setLoad(true);
            store.dispatch(setLoading(true))
            if(response.success){
                MySwal.fire({
                    icon: 'success',
                    title: `${i18n.t('general.order_transmitted')}`,
                    html: `${i18n.t('general.enjoy_your_meal')}`,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: `${i18n.t('general.ok')}`,
                    allowOutsideClick: false
                }).then((result) => {
                    store.dispatch(destroyAllBasket([]));
                    store.dispatch(setLoading(false))
                    window.location.reload();
                })
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: `${i18n.t('general.error')}`,
                    html: `${response.message}`,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: `${i18n.t('general.ok')}`,
                    allowOutsideClick: false
                }).then((result) => {
                    store.dispatch(destroyAllBasket([]));
                    window.location.reload();
                })
            }

        })
        .catch((error) => {
            MySwal.fire({
                icon: 'error',
                title: `${i18n.t('general.error')}`,
                html: `${i18n.t('general.error')}`,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: `${i18n.t('general.ok')}`,
                allowOutsideClick: false
            }).then((result) => {
                window.location.reload();
            })
        })
}

export const TranslatedAttribute = (locale, item, attribute) => {
    try {
        if (!item)
            return "";
        let name = item[attribute];
        if (item.item_translations && item.item_translations[locale] && item.item_translations[locale][attribute]) {
            name = item.item_translations[locale][attribute]
        }
        return name;
    } catch {
        return ""
    }
}

export const noteScreenSaveAndClose = (getData) => {
    // getData.setLoad(true);
    store.dispatch(setLoading(true))
    getData.setSaveButtonDisable(true);

    if(Boolean(getData.name)){
        // getData.setLoad(true);
        setOrder(getData)
            .catch(err => {
                getData.setModalOpen(false);
                // getData.setLoad(false);
                store.dispatch(setLoading(false))
                MySwal.fire({
                    title: err.title,
                    html: err.html,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: err.confirm,
                    allowOutsideClick: false
                }).then((result) => {
                    // getData.setLoad(false);
                    store.dispatch(setLoading(false))
                    getData.setModalOpen(false);
                    window.location.reload();
                })
            }).then(() => {
            getData.setSaveButtonDisable(false);
            });
    }else{
        toast.error(i18n.t('general.name_field_cannot_empty'))
        getData.setSaveButtonDisable(false);
        return false;
    }
};

export const addToCart = (getData, destroyModal, basket) => {

    let requiredCheck = getData.requiredVariantCheck();

    if(requiredCheck.isEmpty){
        toast.error(TranslatedAttribute(store.getState().app.locale, requiredCheck.requiredField, 'name') +' '+ i18n.t('general.required_field'));
        return false;
    }

    let variantArr = [];

    Object.values(getData.variantArray).forEach(item =>{
        variantArr = [...variantArr, {"values": item}];
    });

    console.log(store.getState().app.locale);

    const result =
        {
            "identifier": new Date().getTime(),
            "id": getData.data.id,
            "name":  TranslatedAttribute(store.getState().app.locale, getData.data, 'name'),
            "price": getData.modal.data.price[getData.currencySymbol],
            "amount": getData.quantity,
            "total_price": getData.totalPrice,
            "unit_price": 0,
            "variants": variantArr,
            "options": getData.optionArray
        }


    store.dispatch(setBasket(result))
    //const arr = Object.assign({}, basket.items)
    //basket.items = [...[result], ...[basket.items]]
    toast.success(i18n.t('general.successfully_added_to_cart'));

    destroyModal();
}

export const groupMainCategoryProducts = (data) => {
    let dataTemp = JSON.parse(JSON.stringify(data));
    dataTemp.forEach(category => {
        let tempCategory = {...category}
        let sub_categories = []
        tempCategory.sub_items = []
        category.sub_items.forEach(item => {
            if(item.item_type === 'product') {
                tempCategory.sub_items.push(item);
            } else {
                sub_categories.push(item);
            }
        })
        if (tempCategory.sub_items.length > 0)
            category.sub_items = [tempCategory].concat(sub_categories);
    })
    return dataTemp
}