import {ThreeDots} from "react-loader-spinner";
import * as React from "react";

const Loader = () => {
    return (
        <div className={'w-full bg-[#9FA690] bg-opacity-80 h-screen fixed left-0 right-0 bottom-0 top-0 z-[10000] flex flex-row justify-center items-center'}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#8d3d3d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default Loader;