import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faFoursquare,
    faInstagram,
    faTiktok,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {faWifi} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {createModal} from "../../ImageDefault/utils/modal";

const SocialMediaIcons = ({social_medias}) => {
    const social_alias = {
        facebook: {
            icon: faFacebook,
            base_url: 'https://www.facebook.com/'
        },
        instagram: {
            icon: faInstagram,
            base_url: 'https://www.instagram.com/'
        },
        youtube: {
            icon: faYoutube,
            base_url: 'https://www.youtube.com/@'
        },
        tiktok: {
            icon: faTiktok,
            base_url: 'https://www.tiktok.com/@'
        },
        twitter:{
            icon: faTwitter,
            base_url: 'https://twitter.com/'
        },
        foursquare: {
            icon: faFoursquare,
            base_url: 'https://www.foursquare.com/v/'
        }};

    return (
        <div className={'flex mt-4 justify-center w-full px-[70px]'}>
            <div className={'w-[30px] h-[30px] mx-[7px] bg-white border rounded-[5px] flex items-center justify-center'}
                 onClick={() => createModal('wifi_modal')}>
                <FontAwesomeIcon icon={faWifi} size={'lg'} color={'#9FA690'}/>
            </div>
            {
                Object.keys(social_medias).map((sm) => {
                    return (
                        <div key={sm}>
                            {social_medias[sm] !== "" && (
                                <div
                                    className={'w-[30px] h-[30px] mx-[7px] bg-white border rounded-[5px] flex items-center justify-center'}
                                    onClick={() => {
                                        window.open(social_alias[sm].base_url + social_medias[sm], '_blank');
                                    }}>
                                    <FontAwesomeIcon icon={social_alias[sm].icon}
                                                     size={'xl'} color={'#9FA690'}/>

                                </div>
                            )
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default SocialMediaIcons;