import i18n from "i18next";
import ICU from 'i18next-icu';
import { initReactI18next } from "react-i18next";
import {en_translations} from "./locales/en";
import {tr_translations} from "./locales/tr";
import {ru_translations} from "./locales/ru";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(ICU)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "tr",
        interpolation: {
            escapeValue: false,
        },
        resources:{
            tr: tr_translations,
            en: en_translations,
            ru: ru_translations
        },
        detection: {

        }
});



export default i18n;
