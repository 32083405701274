import * as React from "react";
import Category from "./Category";
import Product from "../Products/Product";
import ProductList from "../Products/ProductList";
import {createModal} from "../../utils/modal";

const Categories = ({items}) => {

    return (
        <div>
            <div className={'w-100 relative overflow-y-auto whitespace-nowrap no-scrollbar'}>
                { items && (
                    items.map(item => {
                        return (
                            <div key={'item-' + item.id} className={'mt-[15px]'}>
                                {
                                    item.item_type === 'category' ? (
                                        <Category category={item}/>
                                    ) : (
                                        !item.sub_items ? (
                                            <div className={'h-[110px] rounded-[12px] bg-[#ECDFCD] flex text-black shadow-md'} onClick={() => { createModal('product_detail', item) }}>
                                                <Product item={item}/>
                                            </div>
                                        ) : (
                                            <div>
                                                <ProductList item={item}/>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}
export default Categories;