import Product from "./Product";
import {createModal} from "../../utils/modal";
import {useState} from "react";
import Collapse from "@mui/material/Collapse";

const ProductList = ({ item }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    return (
        <div className={'bg-[#ECDFCD] rounded-[12px]'} key={'product-' + item.id}>
            <div className={'h-[110px] rounded-[12px] bg-[#ECDFCD] flex text-black shadow-md'} onClick={() => { toggle(); }}>
                <Product item={item} key={'product-' + item.id} />
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
            {
                item.sub_items.map(item => {
                    return (
                        <div className={'h-[75px] rounded-[12px] bg-[#F4ECE1] flex text-black mb-[5px] mx-[5px] items-center shadow-md'}
                             onClick={() => { createModal('product_detail', item) }}
                             key={'sub-product-' + item.id}>
                            <Product item={item} sub_product={true}/>
                        </div>
                    )
                })
            }
            </Collapse>
        </div>
    )
}

export default ProductList;