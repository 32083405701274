import {useSelector} from "react-redux";
import DefaultProductImg from "../../../../assets/img/default_product.jpeg";
import DefaultSubProductImg from "../../../../assets/img/default_sub_product.jpeg";
import {createModal, useModal} from "../../utils/modal";
import {TranslatedAttribute} from "../../../../utils";

const Product = ({item, sub_product = false}) => {
    const {currencySymbol} = useSelector(state => state.app);
    const {locale} = useSelector(state => state.app)
    const render_product = () => {
        return (
            <>
                <img className={'rounded-[12px] my-[10px] ml-[10px] w-[90px] h-auto object-cover'} src={item.image_url || DefaultProductImg}/>
                <div className={'ml-2 flex flex-col my-[5px]'}>
                    <p className={'title text-[15pt] font-medium whitespace-pre line-clamp-1 text-wrap'}>
                        {TranslatedAttribute(locale, item, 'name')}
                    </p>
                    <p className={'desc text-[10pt] mb-auto line-clamp-2 text-wrap'}>{item.description}</p>
                    <div className={'desc text-[12pt] mt-auto'}>
                        {
                            item.price[currencySymbol].toString().includes('-') ? (
                                <>
                                    <p className={'text-[15pt] font-medium inline'}>
                                        {parseFloat(item.price[currencySymbol].split('-')[0]).toFixed(2)}
                                    </p>
                                    <p className={'inline ml-1 mr-2'}>
                                        {currencySymbol}
                                    </p>
                                    -
                                    <p className={'text-[15pt] font-medium inline ml-2'}>
                                        {parseFloat(item.price[currencySymbol].split('-')[1]).toFixed(2)}
                                    </p>
                                    <p className={'inline ml-1'}>
                                        {currencySymbol}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p className={'text-[15pt] font-medium inline'}>
                                        {parseFloat(item.price[currencySymbol]).toFixed(2)}
                                    </p>
                                    <p className={'inline ml-1'}>
                                        {currencySymbol}
                                    </p>
                                </>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
    const render_sub_product = () => {
        return (
            <>
                <img className={'rounded-[12px] my-[10px] ml-[10px] w-[55px] h-auto object-cover'}
                     src={item.image_url || DefaultSubProductImg}/>
                <div className={'ml-2 flex flex-col my-[5px]'}>
                    <p className={'title text-[13pt] font-medium whitespace-pre line-clamp-1 text-wrap'}>
                        {TranslatedAttribute(locale, item, 'name')}
                    </p>
                    <p className={'desc text-[8pt] mb-auto line-clamp-1 text-wrap'}>{item.description}</p>
                </div>
                <p className={'desc text-[10pt] ml-auto mr-1'}>
                    {
                        item.price[currencySymbol].toString().includes('-') ? (
                            <>
                                <p className={'text-[13pt] font-medium inline'}>
                                    {parseFloat(item.price[currencySymbol].split('-')[0]).toFixed(2)}
                                </p>
                                <p className={'inline ml-2 mr-2'}>
                                    {currencySymbol}
                                </p>
                                -
                                <p className={'text-[13pt] font-medium inline ml-2'}>
                                    {parseFloat(item.price[currencySymbol].split('-')[1]).toFixed(2)}
                                </p>
                                <p className={'inline ml-1'}>
                                    {currencySymbol}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={'text-[13pt] font-medium inline'}>
                                    {parseFloat(item.price[currencySymbol]).toFixed(2)}
                                </p>
                                <p className={'inline ml-1'}>
                                    {currencySymbol}
                                </p>
                            </>
                        )
                    }
                </p>
            </>
        )
    }
    return (
        <>
            {
                sub_product ? (
                    render_sub_product()
                ) : (
                    render_product()
                )
            }
        </>
    )
}

export default Product
