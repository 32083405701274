import {useSelector} from "react-redux";
import {createModal} from "../../utils/modal";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";

const HeaderButton = () => {
    const {locale} = useSelector(state => state.app)

    return (
        <div>
            <div onClick={() => createModal('language')} className={'w-[45px] h-[45px] rounded-xl flex justify-center items-center shadow-md z-20 right-[5px] top-[10px] border border-[#ECDFCD]'}>
                <FontAwesomeIcon icon={faGlobe} color={'#ecdfcd'} className={'font-bold m-1 w-[20px] h-[20px]'} />
            </div>
        </div>
    )
}

export default HeaderButton;
